import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  api = environment.production
    ? 'https://api.combot.precoce-radio.fr/'
    : 'http://localhost:3000/';

  constructor(protected http: HttpClient) {}

  httpGet<T>(url: string): Observable<T> {
    return this.http.get<T>(this.api + url);
  }

  httpPost(url: string, data: any) {
    return this.http.post(this.api + url, data);
  }
}
