import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Tirage du jour', url: '/app/tirage-du-jour', icon: 'mail' },
    // { title: 'Outbox', url: '/app/Outbox', icon: 'paper-plane' },
    // { title: 'Favorites', url: '/app/Favorites', icon: 'heart' },
    // { title: 'Archived', url: '/app/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/app/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/app/Spam', icon: 'warning' },
  ];
  constructor() {}
}
