import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Fleur } from '../interfaces/fleur';
import { map } from 'rxjs/operators';
import { Picture } from '../picture';

@Injectable({
  providedIn: 'root',
})
export class FleursService extends ApiService {
  endpoint = 'fleurs';
  constructor(protected http: HttpClient) {
    super(http);
  }

  // getdata from endpoint/fleursDuJour then format them using fleurToPicture then return them
  getFleurs() {
    return this.httpGet<Fleur[]>(this.endpoint + '/fleursDuJour').pipe(
      map((fleurs: Fleur[]) =>
        fleurs.map((fleur: Fleur) => this.fleurToPicture(fleur))
      )
    );
  }

  fleurToPicture(fleur: Fleur): Picture {
    return {
      path: fleur.imageurl,
      title: fleur.nom,
      description: fleur.signification,
    };
  }
}
